<template>
	<div>
		<CCard>
			<CCardHeader>
				<h4 class="center-text">
					Por Autorizar
				</h4>
			</CCardHeader>
			<CCardBody>
				<div v-if="this.showContent === 'information'" id="information">
					<CRow>
						<CCol sm="12">
							<p class="h6 justify-text">
								En este m&oacute;dulo puedes visualizar las
								solicitudes de autorizaci&oacute;n enviadas para
								ser aprobadas por el cliente.
							</p>
							<p class="h6 justify-text">
								Puedes usar el campo con el icono
								<i class="fas fa-search"></i> para filtrar los
								resultados.
							</p>
							<p class="h6 justify-text">
								Para reenviar una solicitud haz clic en el
								&iacute;cono
								<i
									class="fas fa-envelope fa-lg"
									style="color: #2EB85C;"
								></i>
							</p>
							<p class="h6 justify-text">
								Para eliminar una solicitud haz clic en el
								&iacute;cono
								<i
									class="fas fa-ban fa-lg"
									style="color: #E55353;"
								></i>
							</p>
							<p class="h6 justify-text">
								Para cambiar el e-mail del cliente haz clic en
								el &iacute;cono
								<i
									class="fas fa-edit fa-lg"
									style="color: #39F;"
								></i>
							</p>
							<p class="h6 justify-text">
								<span class="h6 badge badge-danger"
									>Importante:
								</span>
								Recuerda que al eliminar una solicitud el
								cliente ya
								<b>no podr&aacute;</b> autorizar el acceso de su
								feed de instagram mediante el widget de
								<b>Infoguia</b>.
								<br />
								<b>
									Por favor usa esta opci&oacute;n con
									precauci&oacute;n.
								</b>
							</p>
						</CCol>
					</CRow>
					<!-- buscador de la tabla -->
					<search-tables @search="doSearch" />
					<!-- tabla -->
					<CRow>
						<CCol sm="12">
							<p style="margin-bottom: 30px;">
								<span class="float-right"
									>Total de solicitudes:
									<CBadge shape="pill" color="info">
										{{
											this.dataForPagination.totalRecords
										}}</CBadge
									>
								</span>
							</p>
							<b-table
								class="text-left"
								stacked="lg"
								striped
								outlined
								hover
								bordered
								:busy.sync="isBusy"
								:items="tableProvider"
								:fields="fieldsTable"
								empty-text="No existen solicitudes por autorizar"
								ref="dataTable"
								show-empty
							>
								<template #empty="scope">
									<p class="h6 justify-text">
										{{ scope.emptyText }}
									</p>
								</template>
								<template #cell(actions)="row">
									<span
										@click="sendRequest(row.item)"
										style="cursor: pointer;"
										title="Reenviar"
										v-b-tooltip.hover.v-success
									>
										<i
											class="fas fa-envelope fa-lg green-email"
										></i>
									</span>
									<span
										@click="confirmDelete(row.item)"
										style="margin-left:5px; margin-right:5px; cursor: pointer;"
										title="Eliminar"
										v-b-tooltip.hover.v-danger
									>
										<i
											class="fas fa-ban fa-lg danger-red"
										></i>
									</span>
									<span
										@click="changeMail(row.item)"
										style="cursor: pointer;"
										title="Cambiar"
										v-b-tooltip.hover.v-primary
									>
										<i
											class="fas fa-edit fa-lg edit-blue"
										></i>
									</span>
								</template>
							</b-table>
						</CCol>
					</CRow>
					<!-- paginacion -->
					<pager
						v-if="this.dataForPagination.totalRecords > 0"
						:data-pager="dataForPagination"
						@changePage="changePage"
					/>
					<!-- modales -->
					<edit-email
						v-if="editMail"
						:trigger-modal="true"
						:email="emailForEdit"
						@closeModal="editMail = false"
						@editEmail="newEmail"
					/>
					<success-modal
						v-if="showSuccessModal"
						:trigger-modal="true"
						:title-modal="this.titleSuccessModal"
						:text-modal="this.textSuccessModal"
						:redirect="false"
						@closeModal="showSuccessModal = false"
					/>
					<delete-request
						v-if="showDeleteModal"
						:trigger-modal="true"
						:comercial-name="this.comercialNameClientSelected"
						@closeModal="showDeleteModal = false"
						@cancelRequest="cancelRequest"
					/>
				</div>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
	import Pager from '../../components/general/pager.vue';
	import SearchTables from '../../components/general/searchTables.vue';
	import DeleteRequest from '../../components/modals/deleteRequest.vue';
	import EditEmail from '../../components/modals/editEmail.vue';
	import SuccessModal from '../../components/modals/successModal.vue';
	import functionsForAdminWidgets from '../../functions/functionsForAdminWidgets';
	export default {
		components: {
			EditEmail,
			SuccessModal,
			DeleteRequest,
			Pager,
			SearchTables,
		},
		name: 'pendingAuth',
		title: 'Info Feed | Infoguia',
		data() {
			return {
				showContent: 'information',
				customerData: {},
				dataTable: [],
				fieldsTable: [
					{
						key: 'id',
						label: 'ID',
					},
					{
						key: 'comercialName',
						label: 'Nombre Comercial',
					},
					{
						key: 'seller',
						label: 'Ejecutivo de Ventas',
					},
					{
						key: 'email',
						label: 'E-Mail',
					},
					{
						key: 'sendDate',
						label: 'Fecha de Envío',
					},
					{
						key: 'status',
						label: 'Estatus',
					},
					{
						key: 'actions',
						label: 'Acciones',
					},
				],
				dataForPagination: {
					recordsPerPage: process.env.VUE_APP_RECORDS_PER_PAGE,
					currentPage: 1,
					totalRecords: 0,
				},
				editMail: false,
				emailForEdit: '',
				idCustomersSelected: '',
				comercialNameClientSelected: '',
				isBusy: false,
				page: null,
				textSuccessModal: '',
				showSuccessModal: false,
				titleSuccessModal: 'Reenvio Exitoso',
				showDeleteModal: false,
				searchText: null,
			};
		},
		created() {},
		methods: {
			confirmDelete(rowTodelete) {
				this.showDeleteModal = true;
				this.comercialNameClientSelected = rowTodelete.comercialName;
				this.idCustomersSelected = rowTodelete.idCustomers;
			},
			cancelRequest() {
				functionsForAdminWidgets
					.deleteRequest(this.idCustomersSelected)
					.then((response) => {
						this.titleSuccessModal = 'Eliminaci&oacute;n Exitosa';
						this.textSuccessModal =
							'Eliminaste exitosamente la solicitud del cliente: <b>' +
							this.comercialNameClientSelected +
							'</b>';
						this.showDeleteModal = false;
						this.showSuccessModal = true;
						//this.tableProvider();
						this.$refs.dataTable.refresh();
					})
					.catch((error) => {
						console.log(error);
					});
			},
			changeMail(rowTable) {
				this.emailForEdit = rowTable.email;
				this.idCustomersSelected = rowTable.idCustomers;
				this.comercialNameClientSelected = rowTable.comercialName;
				this.editMail = true;
			},
			newEmail(newEmail) {
				let dataForPost = {
					email: newEmail,
				};
				functionsForAdminWidgets
					.postNewEmail(dataForPost, this.idCustomersSelected)
					.then((response) => {
						this.dataTable.map((item) => {
							if (item.idCustomers === response.idCustomers) {
								item.email = response.email;
							}
							return item;
						});
						this.titleSuccessModal = 'Correo Cambiado';
						this.textSuccessModal =
							'Cambiaste el correo exitosamente del cliente: <b>' +
							this.comercialNameClientSelected +
							'</b>';
						this.editMail = false;
						this.showSuccessModal = true;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			changePage(page) {
				this.dataTable = [];
				this.page = page;
				//this.tableProvider();
				this.$refs.dataTable.refresh();
			},
			async tableProvider() {
				let promise = functionsForAdminWidgets.getPendingAuth(
					this.dataForPagination.recordsPerPage,
					this.page,
					this.searchText
				);
				try {
					const response = await promise;
					const items = [];
					this.isBusy = false;
					this.dataForPagination = {
						recordsPerPage: response.per_page,
						currentPage: response.current_page,
						totalRecords: response.total,
					};
					for (let index = 0; index < response.data.length; index++) {
						items[index] = {
							id: response.data[index].idCustomers,
							comercialName: response.data[index].comercialName,
							seller:
								response.data[index].sellerName !== null
									? response.data[index].sellerName
									: 'S/I',
							email: response.data[index].email,
							sendDate: response.data[index].sendDate,
							status: response.data[index].status.name,
							bussinessCode: response.data[index].id_company,
							idCustomers: response.data[index].idCustomers,
						};
					}
					this.dataTable = items;
					return items;
				} catch (error) {
					console.log(error);
					return [];
				}
			},
			sendRequest(rowTable) {
				let bussinessCode = rowTable.bussinessCode;
				let dataForPost = {
					idCustomers: rowTable.idCustomers,
					email: rowTable.email,
				};
				functionsForAdminWidgets
					.postRequest(dataForPost, bussinessCode)
					.then((response) => {
						this.textSuccessModal =
							'Reenviaste exitosamente la solicitud de autorizaci&oacute;n a <b>' +
							rowTable.comercialName +
							'</b>';
						this.showSuccessModal = true;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			doSearch(value) {
				this.searchText = value;
				this.$refs.dataTable.refresh();
			},
		},
	};
</script>

<style></style>
