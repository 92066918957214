<template>
	<b-modal
		v-model="triggerModal"
		centered
		no-close-on-backdrop
		header-bg-variant="danger"
		header-text-variant="light"
		style="text-align:justify;"
		size="lg"
	>
		<CRow>
			<CCol>
				<p class="h6 justify-text">
					Estas intentando eliminar la solicitud de
					autorizaci&oacute;n del cliente:
				</p>
				<p class="h6 center-text">
					<b>{{ comercialName }}</b>
				</p>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<p class="h6 justify-text">
					<span class="h6 badge badge-danger">Importante: </span>
					Recuerda que si eliminas esta solicitud el cliente ya
					<b>no podr&aacute;</b> autorizar el acceso de su feed de
					Instagram mediante el widget de <b>Infoguia</b>.
				</p>
			</CCol>
		</CRow>
		<CRow>
			<CCol sm="12">
				<p class="h6 justify-text">
					Si deseas realmente eliminar esta solicitud por favor
					escribe
					<b>Eliminar</b> en el siguiente campo y luego haz clic en el
					bot&oacute;n <b>Eliminar Solicitud</b> una vez se habilite
					el mismo.
				</p>
			</CCol>
		</CRow>
		<CRow>
			<CCol sm="4">
				<CInput v-model="textConfirm"></CInput>
			</CCol>
		</CRow>
		<template v-slot:modal-header>
			<h6 class="modal-title">Eliminar Solicitud</h6>
			<CButtonClose @click="closeModal" class="text-white" />
		</template>
		<template v-slot:modal-footer>
			<b-button
				@click="cancelRequest"
				pill
				variant="danger"
				size="lg"
				class="mr-1"
				:disabled="textConfirm !== 'Eliminar' ? true : false"
			>
				Eliminar solicitud
			</b-button>
			<b-button
				@click="closeModal"
				pill
				variant="info"
				size="lg"
				class="mr-1"
				>No eliminar
			</b-button>
		</template>
	</b-modal>
</template>

<script>
	export default {
		name: 'deletRequest',
		props: {
			triggerModal: {
				type: Boolean,
				default: function() {
					return false;
				},
			},
			comercialName: {
				type: String,
				default: function() {
					return '';
				},
			},
			idCustomer: {
				type: Number,
				default: function() {
					return 0;
				},
			},
		},
		data() {
			return {
				textConfirm: '',
			};
		},
		methods: {
			closeModal() {
				this.textConfirm = '';
				this.$emit('closeModal');
			},
			cancelRequest() {
				this.$emit('cancelRequest');
			},
		},
	};
</script>

<style></style>
